import * as ACTION_TYPES from './affectationActionTypes';



// New affectation data actions
export const setNewAffectationDataSelectedCredits = data => ({
	type: ACTION_TYPES.SET_NEW_AFFECTATION_DATA_SELECTED_CREDITS,
	payload: {
		data
	}
});
export const clearNewAffectationDataSelectedCredits = () => ({
	type: ACTION_TYPES.CLEAR_NEW_AFFECTATION_DATA_SELECTED_CREDITS
});
// END New affectation data actions



// New affectation administrative document actions
export const setNewAffectationAdministrativeDocumentData = data => ({
	type: ACTION_TYPES.SET_NEW_AFFECTATION_ADMINISTRATIVE_DOCUMENT_DATA,
	payload: {
		data
	}
});
export const clearNewAffectationAdministrativeDocumentData = () => ({
	type: ACTION_TYPES.CLEAR_NEW_AFFECTATION_ADMINISTRATIVE_DOCUMENT_DATA
});
// END New affectation administrative document actions



// Credit query data actions
export const setCreditQueryDataCreditSelected = data => ({
	type: ACTION_TYPES.SET_CREDIT_QUERY_DATA_CREDIT_SELECTED,
	payload: {
		data
	}
});
export const clearCreditQueryDataCreditSelected = () => ({
	type: ACTION_TYPES.CLEAR_CREDIT_QUERY_DATA_CREDIT_SELECTED
});
// END Credit query data actions



// Affectation history data actions
export const setAffectationHistoryDataCreditSelected = data => ({
	type: ACTION_TYPES.SET_AFFECTATION_HISTORY_DATA_CREDIT_SELECTED,
	payload: {
		data
	}
});
export const clearAffectationHistoryDataCreditSelected = () => ({
	type: ACTION_TYPES.CLEAR_AFFECTATION_HISTORY_DATA_CREDIT_SELECTED
});
// END Affectation history data actions



// Affectation types request actions
export const getAffectationTypesRequest = () => ({
	type: ACTION_TYPES.GET_AFFECTATION_TYPES_REQUEST
});
export const getAffectationTypesRequestSuccess = data => ({
	type: ACTION_TYPES.GET_AFFECTATION_TYPES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getAffectationTypesRequestFailure = error => ({
	type: ACTION_TYPES.GET_AFFECTATION_TYPES_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Affectation types request actions




// Create affectation request actions
export const createAffectationRequest = () => ({
	type: ACTION_TYPES.CREATE_AFFECTATION_REQUEST
});
export const createAffectationRequestSuccess = responseData => ({
	type: ACTION_TYPES.CREATE_AFFECTATION_REQUEST_SUCCESS,
	payload: {
		responseData
	}
});
export const createAffectationRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_AFFECTATION_REQUEST_FAILURE,
	payload: {
		error
	}
});
/// END Create affectation request actions

// Create disaffection request actions
export const createDisaffectionRequest = () => ({
	type: ACTION_TYPES.CREATE_DISAFFECTION_REQUEST
});
export const createDisaffectionRequestSuccess = responseData => ({
	type: ACTION_TYPES.CREATE_DISAFFECTION_REQUEST_SUCCESS,
	payload: {
		responseData
	}
});
export const createDisaffectionRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_DISAFFECTION_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Create disaffection request actions

// Create conversion to passive remaining actions
export const createConversionToPassiveRemainingRequest = () => ({
	type: ACTION_TYPES.CREATE_CONVERSION_TO_PASSIVE_REMAINING_REQUEST
});
export const createConversionToPassiveRemainingRequestSuccess = responseData => ({
	type: ACTION_TYPES.CREATE_CONVERSION_TO_PASSIVE_REMAINING_REQUEST_SUCCESS,
	payload: {
		responseData
	}
});
export const createConversionToPassiveRemainingRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_CONVERSION_TO_PASSIVE_REMAINING_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END conversion to passive remaining actions

// Reverse passive remaining actions
export const reversePassiveRemainingRequest = () => ({
	type: ACTION_TYPES.REVERSE_PASSIVE_REMAINING_REQUEST
});
export const reversePassiveRemainingRequestSuccess = responseData => ({
	type: ACTION_TYPES.REVERSE_PASSIVE_REMAINING_REQUEST_SUCCESS,
	payload: {
		responseData
	}
});
export const reversePassiveRemainingRequestFailure = error => ({
	type: ACTION_TYPES.REVERSE_PASSIVE_REMAINING_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END reverse passive remaining actions

// generate passive remaining  by service actions
export const createConversionToPassiveRemainingByServiceRequest = () => ({
	type: ACTION_TYPES.CREATE_CONVERSION_TO_PASSIVE_REMAINING_BY_SERVICE_REQUEST
});
export const createConversionToPassiveRemainingByServiceRequestSuccess = responseData => ({
	type: ACTION_TYPES.CREATE_CONVERSION_TO_PASSIVE_REMAINING_BY_SERVICE_REQUEST_SUCCESS,
	payload: {
		responseData
	}
});
export const createConversionToPassiveRemainingByServiceRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_CONVERSION_TO_PASSIVE_REMAINING_BY_SERVICE_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END generate passive remaining by service actions

// Revert passive remaining by service
export const revertPassiveRemainingByServiceRequest = () => ({
	type: ACTION_TYPES.REVERT_PASSIVE_REMAINING_BY_SERVICE_REQUEST
});
export const revertPassiveRemainingByServiceRequestSuccess = responseData => ({
	type: ACTION_TYPES.REVERT_PASSIVE_REMAINING_BY_SERVICE_REQUEST_SUCCESS,
	payload: {
		responseData
	}
});
export const revertPassiveRemainingByServiceRequestFailure = error => ({
	type: ACTION_TYPES.REVERT_PASSIVE_REMAINING_BY_SERVICE_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END revert passive remaining by service

// Affectation transactions actions
export const getAffectationTransactionsRequest = () => ({
	type: ACTION_TYPES.GET_AFFECTATION_TRANSACTIONS_REQUEST
});
export const getAffectationTransactionsRequestSuccess = data => ({
	type: ACTION_TYPES.GET_AFFECTATION_TRANSACTIONS_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getAffectationTransactionsRequestFailure = error => ({
	type: ACTION_TYPES.GET_AFFECTATION_TRANSACTIONS_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearAffectationTransactions = () => ({
	type: ACTION_TYPES.CLEAR_AFFECTATION_TRANSACTIONS
});
// END Affectation transactions actions



// Get availables affectation status actions
export const getAvailablesAffectationStatusRequest = () => ({
	type: ACTION_TYPES.GET_AVAILABLES_AFFECTATION_STATUS_REQUEST
});
export const getAvailablesAffectationStatusRequestSuccess = data => ({
	type: ACTION_TYPES.GET_AVAILABLES_AFFECTATION_STATUS_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getAvailablesAffectationStatusRequestFailure = error => ({
	type: ACTION_TYPES.GET_AVAILABLES_AFFECTATION_STATUS_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Get availables affectation status actions

//Get available affectation history transaction by id actions
export const getAvailablesAffectationsHistoryTransactionsByIdRequest = () => ({
	type: ACTION_TYPES.GET_AVAILABLES_AFFECTATION_HISTORY_TRANSACTION_BY_ID_REQUEST
});
export const getAvailablesAffectationsHistoryTransactionsByIdRequestSuccess = data => ({
	type: ACTION_TYPES.GET_AVAILABLES_AFFECTATION_HISTORY_TRANSACTION_BY_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getAvailablesAffectationsHistoryTransactionsByIdRequestFailure = error => ({
	type: ACTION_TYPES.GET_AVAILABLES_AFFECTATION_HISTORY_TRANSACTION_BY_ID_REQUEST_FAILURE,
	payload: {
		error
	}
});
//END Get available affectation history transaction by id

// Get availables affectation status of selected credits actions
export const getAvailablesAffectationStatusOfSelectedCreditsRequest = () => ({
	type: ACTION_TYPES.GET_AVAILABLES_AFFECTATION_STATUS_OF_SELECTED_CREDITS_REQUEST
});
export const getAvailablesAffectationStatusOfSelectedCreditsRequestSuccess = data => ({
	type: ACTION_TYPES.GET_AVAILABLES_AFFECTATION_STATUS_OF_SELECTED_CREDITS_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getAvailablesAffectationStatusOfSelectedCreditsRequestFailure = error => ({
	type: ACTION_TYPES.GET_AVAILABLES_AFFECTATION_STATUS_OF_SELECTED_CREDITS_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Get availables affectation status of selected credits actions

// Get affectation subcode actions
export const getAffectationSubcodeRequest = () => ({
	type: ACTION_TYPES.GET_AFFECTATION_SUBCODE_REQUEST
});
export const getAffectationSubcodeRequestSuccess = data => ({
	type: ACTION_TYPES.GET_AFFECTATION_SUBCODE_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getAffectationSubcodeRequestFailure = error => ({
	type: ACTION_TYPES.GET_AFFECTATION_SUBCODE_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Get affectation subcode actions



// Get balances of selected credits actions
export const getBalancesOfSelectedCreditsRequest = () => ({
	type: ACTION_TYPES.GET_BALANCES_OF_SELECTED_CREDITS_REQUEST
});
export const getBalancesOfSelectedCreditsRequestSuccess = data => ({
	type: ACTION_TYPES.GET_BALANCES_OF_SELECTED_CREDITS_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getBalancesOfSelectedCreditsRequestFailure = error => ({
	type: ACTION_TYPES.GET_BALANCES_OF_SELECTED_CREDITS_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Get balances of selected credits actions

// set date affectation actions
export const setDateAffectation = data => ({
	type: ACTION_TYPES.SET_DATE_AFFECTARION_DATA,
	payload: {
		data
	}
});
export const clearDateAffectation = () => ({
	type: ACTION_TYPES.CLEAR_DATE_AFFECTARION_DATA
});
// END set date affectation actions

// set extract affectation actions
export const setExtractAffectation = data => ({
	type: ACTION_TYPES.SET_EXTRACT_AFFECTATION_DATA,
	payload: {
		data
	}
});
export const clearExtractAffectation = () => ({
	type: ACTION_TYPES.CLEAR_EXTRACT_AFFECTATION_DATA
});
// END set extract affectation actions

// set revert affectation actions
export const setRevertAffectation = data => ({
	type: ACTION_TYPES.SET_REVERT_AFFECTATION,
	payload: {
		data
	}
});
// END set revert affectation actions

// Create balance transfer
export const createBalanceTransferRequest = () => ({
	type: ACTION_TYPES.CREATE_BALANCE_TRANSFER_REQUEST
});
export const createBalanceTransferRequestSuccess = responseData => ({
	type: ACTION_TYPES.CREATE_BALANCE_TRANSFER_REQUEST_SUCCESS,
	payload: {
		responseData
	}
});
export const createBalanceTransferRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_BALANCE_TRANSFER_REQUEST_FAILURE,
	payload: {
		error
	}
});
/// END Create balance transfer request actions