import React, { useState } from 'react';
import { config } from 'src/env.js';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card, Alert, Form, Table, Row, Col, Button, Carousel } from 'react-bootstrap';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getNewAffectationSelectedCredits, getNewAffectationAdministrativeDocumentData, getNewAffectationData, getNewBalanceTransferIsFetching } from 'src/redux/affectation/affectationReducer';
import { faArrowLeft, faArrowRight, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { isNotEmptyArray, isNumeric  } from 'src/services/validationService';
import PageTitle from 'src/components/general/PageTitle';
import StepIndicator from 'src/components/common/StepIndicator';
import ActionIcon from 'src/components/general/ActionIcon';
import { BALANCE_TRANSFER_STEP_FOUR, BALANCE_TRANSFER_STEP_TWO } from 'src/utils/constants';
import { formatterPeso, numberNegativeRed, getYearsOptionsByParam, parseIntOrUndefined } from 'src/utils/utils';
import { cancel, accept, impactsBudgetItems } from 'src/utils/label';
import { setNewAffectationDataSelectedCredits } from 'src/redux/affectation/affectationActions';
import { showWarning, showError } from 'src/redux/globalData/globalDataActionCreator';
import { NumericFormat } from 'react-number-format';
import { tryPostBalanceTransfer } from '../../../redux/affectation/affectationActionCreator';
import AppLoading from 'src/components/common/AppLoading';
import { tryGetAdministrativeDocumentByPeriodIdV2 } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';

const BalanceTransferStepThree = () => {
    const dispatch = useDispatch();
    const minimumYear = 2000;
    const maxNumber = 2147483647;
    const [organizationCode, setOrganizationCode] = useState('');
    const [organizationNumber, setOrganizationNumber] = useState('');
    const [organizationYear, setOrganizationYear] = useState('');

    const handleChangeOrgCode = (value) => {
        if (value?.length <= 4) setOrganizationCode(value)
    };

    const handleChangeOrgNumber = (value) => {
        setOrganizationNumber(value)
    };

    const handleChangeOrgYear = (value) => {
        setOrganizationYear(value)
    };

    //validations
    const enableAddButton = (organizationCode?.length == 4 && !(parseIntOrUndefined(organizationCode) < 1000 && parseIntOrUndefined(organizationCode) != 0)) &&
        organizationNumber &&
        parseIntOrUndefined(organizationYear);

    const withValueLimit = (inputObj) => {
        const { value } = inputObj;
        const maxValue = 999999999999.99;
        const minValue = -999999999999.99;
        if (value <= maxValue && value >= minValue || value == '-') return inputObj;
    };

    const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const yearsOptions = getYearsOptionsByParam(minimumYear, selectedPeriod?.year);

    // Administrative document data
    const administrativeDocumentData = useSelector(state => getNewAffectationAdministrativeDocumentData(state));

    // Credits
    let selectedSubcodes = useSelector(state => getNewAffectationSelectedCredits(state))?.map(item => {
        if (!item?.hasOwnProperty('balanceTransferArray')) {
            item.balanceTransferArray = []
        }
        return item;
    });

    const hasSelectedSubcodes = isNotEmptyArray(selectedSubcodes);
    const numberOfSelectedSubcodes = selectedSubcodes?.length;
    const newAffectationData = useSelector(state => getNewAffectationData(state));
    const newBalanceTransferIsFetching = useSelector(state => getNewBalanceTransferIsFetching(state));

    // Carousel
    const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
    const handleCarouselSelect = selectedIndex => {
        setCarouselActiveIndex(selectedIndex);
    };
    const onClickNextCarouselItem = () => {
        handleCarouselSelect(carouselActiveIndex + 1);
    }
    const onClickBackCarouselItem = () => {
        handleCarouselSelect(carouselActiveIndex - 1);
    }

    // Error handling
    const searchSubcodeCarouselIndex = subcodeId => {
        let retorno;
        selectedSubcodes.forEach((subcode, index) => {
            if (subcode?.id == subcodeId) {
                retorno = index;
            }
        });
        return retorno
    };

    const [errorInSlide, setErrorInSlide] = useState();
    const setErrorInCreditSlide = responseData => {
        const subcodeIdWithError = responseData?.reference;
        const errorMessage = responseData?.message;
        const errorObjToSet = {
            subcodeId: subcodeIdWithError,
            message: errorMessage,
        };
        setErrorInSlide(errorObjToSet);

        // Set visible subcode slide with error
        const subcodeIndex = searchSubcodeCarouselIndex(subcodeIdWithError);
        handleCarouselSelect(subcodeIndex);
    };

    //Actions
    const addExpedientToList = async (subCodeData) => {
        const id = organizationCode.toString() + organizationNumber.toString() + organizationYear.toString();
        const existingExpedient = subCodeData?.balanceTransferArray.filter(item => item?.id == id).length > 0;
        const admDocument = await getAdministrativeDocumentData(organizationCode, organizationNumber, organizationYear);

        if (!existingExpedient) {
            const organizationObj = {
                id,
                organization_code: organizationCode,
                organization_number: organizationNumber,
                organization_year: organizationYear,
                title: admDocument.title,
                amount: undefined
            };
            let newCredits = selectedSubcodes.map(item => {
                if (item?.id == subCodeData?.id) {
                    item.balanceTransferArray = [...item?.balanceTransferArray, organizationObj]
                }
                return item;
            });
            dispatch(setNewAffectationDataSelectedCredits(newCredits));
            handleChangeOrgCode('');
            handleChangeOrgNumber('');
            handleChangeOrgYear('');
        };
    };

    const getAdministrativeDocumentData = async (organizationCode, organizationNumber, organizationYear) => {
        let lengthCharacter = (organizationCode?.length > 4 || organizationCode?.length < 4) || (parseIntOrUndefined(organizationCode) < 1000 && parseIntOrUndefined(organizationCode) != 0);
        if (organizationCode && organizationNumber && isNumeric(organizationYear) && !lengthCharacter) {
            const params = {
                year: parseIntOrUndefined(organizationYear),
                number: parseIntOrUndefined(organizationNumber),
                organismCode: organizationCode,
                periodId: selectedPeriod?.id
            };
            const admDocu = await dispatch(tryGetAdministrativeDocumentByPeriodIdV2(params))
            return admDocu;
        }
    };

    const onClickRemoveExpedeint = (subCodeData, id) => {
        const newExpedienstArray = subCodeData?.balanceTransferArray?.filter(item => item?.id != id);
        let newCredits = selectedSubcodes.map(item => {
            if (item?.id == subCodeData?.id) {
                item.balanceTransferArray = newExpedienstArray
            }
            return item;
        });
        dispatch(setNewAffectationDataSelectedCredits(newCredits));
    };

    const onClickBackButton = () => {
        dispatch(push(BALANCE_TRANSFER_STEP_TWO))
    };

    const validAmounts = () => {
        let valid = true;
        selectedSubcodes?.map(item => {
            const balanceTransferArray = item?.balanceTransferArray;
            if(balanceTransferArray?.length >= 1){
                balanceTransferArray?.map(element => {
                    if (isNaN(element?.amount) || element?.amount == 0) {
                        valid = false;
                    }
                })
            } else {
                valid = false;
            }
        });
        return valid;
    };

    const onClickNextStepButton = () => {
        if (!validAmounts()) {
            showWarning(`Debe cargar todos los importes correctamente.`);
        } else {
            swal({
                title: "Confirmación de nuevo Traspaso de Saldos.",
                text: "¿Seguro que quiere realizar la operación?",
                icon: 'warning',
                buttons: [cancel, accept]
            }).then((userAccept) => {
                if (userAccept) {
                    submitTransferOfBalances();
                }
            });
        }
    };

    const handleTitleChange = (e, id) => {
        const newTitle = e.target.value;
        const updatedSubcodeData = selectedSubcodes.map(subcode => {
            const updatedBalanceTransferArray = subcode.balanceTransferArray.map(item => {
                if (item.id === id) {
                    return { ...item, title: newTitle, isEditing: true };
                }
                return item;
            });
            return { ...subcode, balanceTransferArray: updatedBalanceTransferArray };
        });
        dispatch(setNewAffectationDataSelectedCredits(updatedSubcodeData));
    };

    const handleTitleBlur = (id) => {
        const updatedSubcodeData = selectedSubcodes.map(subcode => {
            const updatedBalanceTransferArray = subcode.balanceTransferArray.map(item => {
                if (item.id === id) {
                    return { ...item, isEditing: false };
                }
                return item;
            });
            return { ...subcode, balanceTransferArray: updatedBalanceTransferArray };
        });
        dispatch(setNewAffectationDataSelectedCredits(updatedSubcodeData));
    };

    const submitTransferOfBalances = () => {
        const objectToSend = {
            exercise: selectedPeriod?.year,
            action: "transfer",
            sourceAdministrativeDocument: {
                organization_number: administrativeDocumentData?.organizationCode,
                number: administrativeDocumentData?.number,
                year: administrativeDocumentData?.year
            },
            targetTransactions: []
        };
        if (hasSelectedSubcodes) {
            objectToSend.targetTransactions = selectedSubcodes.map(item => {
                return {
                    subCodeId: item?.id,
                    targetAdministrativeDocuments: item?.balanceTransferArray?.map(element => {
                        return {
                            organization_number: element?.organization_code,
                            number: element?.organization_number,
                            year: element?.organization_year,
                            amount: element?.amount,
                            title: element?.title
                        }
                    })
                };
            });
        };
        dispatch(tryPostBalanceTransfer(objectToSend)).then(response => {
            if (response?.status == 200) {
                dispatch(push(BALANCE_TRANSFER_STEP_FOUR));
            } else if (response?.status == 422) {
                setErrorInCreditSlide(response?.data);
                showError(response?.data?.message);
            } else {
                showError(response?.data?.message);
            }
        });
    };

    const TotalModifications = (data) => {
        const expedientsArray = data?.balanceTransferArray;
        const totalAmount = expedientsArray?.map((item) => parseFloat(item?.amount) || 0)
            .reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            }, 0);

        return <span className='ml-auto'>
            <span className='text-black-color mr-3'>Total Importe:</span>
            <NumericFormat
                prefix={'$'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={2}
                value={totalAmount}
                defaultValue={0}
                className={'text-black-color text-right'}
                readOnly
            />
        </span>
    };

    const onChangeAffectationAmount = (inputObject, subCodeData, expedientId) => {
        let newCredits = selectedSubcodes.map(item => {
            if (item?.id == subCodeData?.id) {
                item?.balanceTransferArray?.map((expItem) => {
                    if (expItem?.id == expedientId) {
                        expItem.amount = inputObject?.value
                    };
                    return expItem;
                })
            }
            return item;
        });
        dispatch(setNewAffectationDataSelectedCredits(newCredits));
    };

    return (
        <Card className='mb-5'>
            <PageTitle text={impactsBudgetItems} />
            <Container fluid >
                <StepIndicator steps={config.appSettings.steps.balanceTransfer} current={2} className='mt-2 mb-3' />
                <Card className='mb-3'>
                    <Card.Header className='h6'>
                        Traspaso de Saldos de RP
                    </Card.Header>
                    <Card.Body>
                        {
                            hasSelectedSubcodes
                                ?
                                <>
                                    <Card className='shadow mb-3' style={{ borderColor: 'gray', borderRadius: '2px' }}>
                                        <Card.Body className='bg-white'>
                                            <Carousel interval={null} indicators={false} controls={false} activeIndex={carouselActiveIndex} onSelect={handleCarouselSelect} fade>
                                                {
                                                    selectedSubcodes?.map(subcodeData => {
                                                        return (
                                                            <Carousel.Item key={subcodeData?.id}>
                                                                <Form.Group className='text-black-color' as={Row}>
                                                                    <Col sm='6'>
                                                                        <p className='h6 mb-3'>DETALLES DE LA PARTIDA</p>
                                                                    </Col>
                                                                    <Col sm='6'>
                                                                        <p className='h6 mb-3' >DETALLES DEL EXPEDIENTE</p>
                                                                    </Col>
                                                                </Form.Group>
                                                                <Form.Group className='text-black-color' as={Row}>
                                                                    <Col sm='6' >
                                                                        <Table striped borderless hover>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className='text-left' md={4}>Nº de partida:</td>
                                                                                    <td className='text-right' md={4} md-offset={8}>
                                                                                        {subcodeData?.completeNumber}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='text-left' md={4}>Denominación de Partida:</td>
                                                                                    <td className='text-right' md={8}>
                                                                                        {subcodeData?.visibleName}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='text-left font-weight-bold' md={4}>Saldo de la partida:</td>
                                                                                    <td className={`${numberNegativeRed(subcodeData?.balance?.currentBalance)} + text-right font-weight-bold`} md={8}>
                                                                                        {formatterPeso.format(subcodeData?.balance?.currentBalance)}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='text-left font-weight-bold' md={4}>Saldo Preventiva:</td>
                                                                                    <td className={`${numberNegativeRed(subcodeData?.balances?.totals?.preventive)} + text-right font-weight-bold`} md={8}>
                                                                                        {
                                                                                            !isNaN(subcodeData?.balances?.totals?.preventive) ?
                                                                                                formatterPeso.format(subcodeData?.balances?.totals?.preventive)
                                                                                                :
                                                                                                '$ 0,00'
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='text-left font-weight-bold' md={4}>Saldo Definitiva:</td>
                                                                                    <td className={`${numberNegativeRed(subcodeData?.balances?.totals?.definitive)} + text-right font-weight-bold`} md={8}>
                                                                                        {
                                                                                            !isNaN(subcodeData?.balances?.totals?.definitive)
                                                                                                ?
                                                                                                formatterPeso.format(subcodeData?.balances?.totals?.definitive)
                                                                                                :
                                                                                                '$ 0,00'
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='text-left font-weight-bold' md={4}>Saldo obl. Pago:</td>
                                                                                    <td className={`${numberNegativeRed(subcodeData?.balances?.totals?.payment_obligation)} + text-right font-weight-bold`} md={8}>
                                                                                        {
                                                                                            !isNaN(subcodeData?.balances?.totals?.payment_obligation)
                                                                                                ?
                                                                                                formatterPeso.format(subcodeData?.balances?.totals?.payment_obligation)
                                                                                                :
                                                                                                '$ 0,00'
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='text-left font-weight-bold' md={4}>Saldo Pago:</td>
                                                                                    <td className={`${numberNegativeRed(subcodeData?.balances?.totals?.payment)} + text-right font-weight-bold`} md={8}>
                                                                                        {
                                                                                            !isNaN(subcodeData?.balances?.totals?.payment)
                                                                                                ?
                                                                                                formatterPeso.format(subcodeData?.balances?.totals?.payment)
                                                                                                :
                                                                                                '$ 0,00'
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </Col>
                                                                    <Col sm='6'>
                                                                        <Table striped borderless hover>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className='text-left' md={4}>Código de Organismo:</td>
                                                                                    <td className='text-right' md={8}>
                                                                                        {newAffectationData?.administrativeDocument?.organizationCode}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='text-left' md={4}>Número:</td>
                                                                                    <td className='text-right' md={8}>
                                                                                        {newAffectationData?.administrativeDocument?.number}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='text-left' md={4}>Año:</td>
                                                                                    <td className='text-right' md={8}>
                                                                                        {newAffectationData?.administrativeDocument?.year}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='text-left' md={4}>Título:</td>
                                                                                    <td className='text-right' md={8}>
                                                                                        {newAffectationData?.administrativeDocument?.title}
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </Table>
                                                                    </Col>
                                                                </Form.Group>
                                                                <hr className='my-4' />
                                                                <p className='h6 mb-4 text-black-color'>Expedientes y Saldos</p>
                                                                <Row>
                                                                    <Col sm='12'>
                                                                        <Form.Group as={Row}>
                                                                            <Col sm='3' className='mb-0 d-flex align-items-center justify-content-end'>
                                                                                <Form.Label htmlFor='expediente-data' className='mb-0 d-flex align-items-center justify-content-end'>
                                                                                    Expediente:
                                                                                </Form.Label>
                                                                            </Col>
                                                                            <Col sm='7' className='mx-0 px-0  d-flex align-items-center justify-content-end'>
                                                                                <Form.Control
                                                                                    name='organismCode'
                                                                                    type='number'
                                                                                    id='expediente-data'
                                                                                    value={organizationCode}
                                                                                    onChange={(event) => handleChangeOrgCode(event.target.value)}
                                                                                    className='form-control w-100 d-flex align-items-center justify-content-end'
                                                                                    placeholder='Código'
                                                                                    tabIndex='1'
                                                                                    title='Código de Organismo'
                                                                                    min={'0'}
                                                                                />
                                                                                <span className='px-2'>
                                                                                    -
                                                                                </span>
                                                                                <Form.Control
                                                                                    name='number'
                                                                                    type='number'
                                                                                    placeholder='Correlativo'
                                                                                    value={organizationNumber}
                                                                                    onChange={(event) => handleChangeOrgNumber(event.target.value)}
                                                                                    className='form-control w-100 d-flex align-items-center justify-content-end'
                                                                                    tabIndex='2'
                                                                                    min='1'
                                                                                    max={maxNumber}
                                                                                />
                                                                                <span className='px-3'>
                                                                                    /
                                                                                </span>
                                                                                <Form.Control
                                                                                    name='year'
                                                                                    as='select'
                                                                                    value={organizationYear}
                                                                                    onChange={(event) => handleChangeOrgYear(event.target.value)}
                                                                                    tabIndex='3'
                                                                                >
                                                                                    <option value={''}>Año</option>
                                                                                    {
                                                                                        yearsOptions?.map((item, idx) => (<option value={item} key={idx}>
                                                                                            {item}
                                                                                        </option>))
                                                                                    }
                                                                                </Form.Control>
                                                                                <Button
                                                                                    className='ml-3 text-white-color'
                                                                                    onClick={() => addExpedientToList(subcodeData)}
                                                                                    disabled={!enableAddButton}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faPlus} />
                                                                                </Button>
                                                                            </Col>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="justify-content-md-center">
                                                                    {
                                                                        subcodeData?.balanceTransferArray?.length > 0 ?
                                                                            <Col sm='10'>
                                                                                <Table striped bordered hover size='sm'>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className='text-center' style={{ "width": "15%" }}>Expediente</th>
                                                                                            <th className='text-center' style={{ "width": "15%" }}>Número</th>
                                                                                            <th className='text-center' style={{ "width": "15%" }}>Año</th>
                                                                                            <th className='text-center' style={{ "width": "25%" }}>Asunto</th>
                                                                                            <th className='text-center' style={{ "width": "20%" }}>Monto</th>
                                                                                            <th className='text-center' style={{ "width": "10%" }}>Acciones</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='text-black-color'>
                                                                                        {
                                                                                            subcodeData?.balanceTransferArray?.map(item => (
                                                                                                <tr key={item?.id}>
                                                                                                    <td className='text-center'>
                                                                                                        {item?.organization_code}
                                                                                                    </td>
                                                                                                    <td className='text-center'>
                                                                                                        {item?.organization_number}
                                                                                                    </td>
                                                                                                    <td className='text-center'>
                                                                                                        {item?.organization_year}
                                                                                                    </td>
                                                                                                    <td className='text-center'>
                                                                                                        {item?.isEditing || !item?.title
                                                                                                            ? <Form.Control
                                                                                                                className='text-center'
                                                                                                                type='text'
                                                                                                                name={`title-${item?.id}`}
                                                                                                                value={item?.title || ''}
                                                                                                                onChange={(e) => handleTitleChange(e, item?.id)}
                                                                                                                onBlur={() => handleTitleBlur(item?.id)}
                                                                                                            />
                                                                                                            : item?.title
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className='text-center'>
                                                                                                        <NumericFormat
                                                                                                            className='form-control'
                                                                                                            decimalScale={2}
                                                                                                            thousandSeparator='.'
                                                                                                            decimalSeparator=','
                                                                                                            inputMode='numeric'
                                                                                                            value={item?.amount}
                                                                                                            defaultValue={item?.amount}
                                                                                                            allowLeadingZeros={false}
                                                                                                            isAllowed={inputObj => withValueLimit(inputObj)}
                                                                                                            onValueChange={inputObject => onChangeAffectationAmount(inputObject, subcodeData, item?.id)}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td className='text-center'>
                                                                                                        <ActionIcon size='lg' toolTipText='Quitar de la selección' icon={faTimes} className='text-danger' onClick={() => onClickRemoveExpedeint(subcodeData, item?.id)} />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </Table>
                                                                                <div className='d-flex align-items-center mb-3'>
                                                                                    <p className='h6 text-black-color'>

                                                                                    </p>
                                                                                    <TotalModifications balanceTransferArray={subcodeData?.balanceTransferArray} />
                                                                                </div>
                                                                            </Col> : null
                                                                    }
                                                                </Row>
                                                                {
                                                                    (errorInSlide && errorInSlide?.subcodeId == subcodeData?.id)
                                                                        ?
                                                                        <Row>
                                                                            <Col>
                                                                                <Alert variant='danger' className='mb-0'>
                                                                                    {errorInSlide?.message}
                                                                                </Alert>
                                                                            </Col>
                                                                        </Row>
                                                                        :
                                                                        null
                                                                }
                                                            </Carousel.Item>
                                                        );
                                                    })
                                                }
                                            </Carousel>
                                        </Card.Body>
                                    </Card>
                                    <Form.Group className='d-flex justify-content-center align-items-center'>
                                        <Button size='xs' onClick={onClickBackCarouselItem} disabled={(carouselActiveIndex == 0)} className='nav-btn'>
                                            <FontAwesomeIcon icon={faArrowLeft} />
                                        </Button>

                                        <span className='px-3 text-black-color'>
                                            {`Partida ${carouselActiveIndex + 1}/${numberOfSelectedSubcodes}`}
                                        </span>

                                        <Button size='xs' onClick={onClickNextCarouselItem} disabled={(carouselActiveIndex == (numberOfSelectedSubcodes - 1))} className='nav-btn'>
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </Button>
                                    </Form.Group>
                                </>
                                : null
                        }
                        <Form.Group className='mt-3 d-flex justify-content-around'>
                            <Button className='text-white-color btn-primary btn-lg' size='lg' onClick={onClickBackButton}>
                                <FontAwesomeIcon className='mr-3' icon={faArrowLeft} />
                                Volver
                            </Button>
                            <Button variant='success' size='lg' onClick={onClickNextStepButton} disabled={newBalanceTransferIsFetching}>
                                Guardar
                            </Button>
                        </Form.Group>
                        <AppLoading isLoading={newBalanceTransferIsFetching} />
                    </Card.Body>
                </Card>
            </Container>
        </Card>
    )
};

export default BalanceTransferStepThree;