import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, httpPost, buildURLQuery } from './httpServices';

const API_BASE_URI = `${config.apis.presupuestoApi.URL}`;
const AFFECTATION_URI = `${API_BASE_URI}/expenses/affectations`;
const AFFECTATION_STATUS_URI = `${API_BASE_URI}/expenses/affectation-status`;
const AFFECTATION_STATUS_AVAILABLES_URI = `${AFFECTATION_STATUS_URI}/availables`;
const AFFECTATION_TRANSACTIONS_URI = `${API_BASE_URI}/expenses/affectation-transactions`;
const AFFECTATION_ADMINISTRATIVE_DOCUMENTS = `${API_BASE_URI}/expenses/administrative-documents`;
const AFFECTATION_HISTORY_TRANSACTIONS = `/affectation-history?transactionId=`;
const BALANCE_TRANSFER_URI = `${API_BASE_URI}/expenses/affectations/transfer`;

export const PREVENTIVA = 1;
export const DEFINITIVA = 2;
export const OBLIGACION_PAGO = 3;
export const PAGO = 4;

export const listAffectationTypes = async( accessToken, params={} ) => {
	const queryString = buildURLQuery(params);
	const URL = `${AFFECTATION_STATUS_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};


export const postAffectation = async( accessToken, body) => {
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpPost( AFFECTATION_URI, body, options);
	}
	catch(error) {
		return error;
	}

	return response;
};

export const postDisaffection = async(accesToken, urlDisaffection, params) => {

	const options = buildDefaultOptions(accesToken);
	const URL = `${API_BASE_URI}/${urlDisaffection}`;
	let response;

	try {
		response = await httpPost(URL, params, options);
	} catch(error) {
		return error;
	}

	return response;
};

export const postConvertionToPassiveRemaining = async(accesToken, params) => {

	const options = buildDefaultOptions(accesToken);
	const URL = `${AFFECTATION_ADMINISTRATIVE_DOCUMENTS}/create-passives-remaining`;

	let response;

	try {
		response = await httpPost(URL, params, options);
	} catch(error) {
		return error;
	}

	return response;
}

export const postReversePassiveRemaining = async(accesToken, params) => {

	const urlToReverse = params?.url;

	const options = buildDefaultOptions(accesToken);
	const URL = urlToReverse;

	let response;

	try {
		response = await httpGet(URL, options);
	} catch(error) {
		return error;
	}

	return response;
};

export const postRevertPassiveRemainingByService = async(accesToken, params) => {

	const options = buildDefaultOptions(accesToken);
	const URL = `${AFFECTATION_ADMINISTRATIVE_DOCUMENTS}/revert-passives-remaining-by-services`;
	
	let response;

	try {
		response = await httpPost(URL, params, options);
	} catch(error) {
		return error;
	}

	return response;
}

export const postConvertionToPassiveRemainingByService = async(accesToken, params) => {

	const options = buildDefaultOptions(accesToken);
	const URL = `${AFFECTATION_ADMINISTRATIVE_DOCUMENTS}/create-passives-remaining-by-services`;
	
	const paramsToSend = {
		services : [
			{
				...params
			}
		]
	}

	let response;

	try {
		response = await httpPost(URL, paramsToSend, options);
	} catch(error) {
		return error;
	}

	return response;
}

// Affectation transactions (Endpoint: /v1/expenses/affectation-transactions)
export const getAffectationTransactions = async ( accessToken, params={} ) => {
	if( params?.filter ) {
		params.filter = JSON.stringify(params.filter);
	}
	const queryString = buildURLQuery(params);
	const URL = `${AFFECTATION_TRANSACTIONS_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};



// Availables affectations status
export const getAvailablesAffectationsStatus = async( accessToken, params ) => {
	if( !params )
		return;

	const paramsToSend = {
		sub_code_id: params?.sub_code_id,
		administrative_document_organism_code: params?.administrative_document_organism_code,
		administrative_document_number: params?.administrative_document_number,
		administrative_document_year: params?.administrative_document_year
	};

	const options = buildDefaultOptions(accessToken);
	const queryString = buildURLQuery(paramsToSend);
	const URL = `${AFFECTATION_STATUS_AVAILABLES_URI}?${queryString}`;

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

// Availables affectations history transactions by Id
export const getAvailablesAffectationsHistoryTransactionsById = async( accessToken, params ) => {
	if( !params )
		return;

	const organismCode = params.organismCode;
	const number = params.number;
	const year = params.year;
	const id = params.id;

	const options = buildDefaultOptions(accessToken);
	const URL = `${AFFECTATION_ADMINISTRATIVE_DOCUMENTS}/${organismCode}/${number}/${year}${AFFECTATION_HISTORY_TRANSACTIONS}${id}`;

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

// affectations subcode
export const getAffectationSubcode = async( accessToken, params ) => {
	if( !params )
		return;

	const paramsToSend = {};
	params.page ? paramsToSend.page = params.page : undefined;
	params.pagesize ? paramsToSend.pagesize = params.pagesize : undefined;
	params.filter ? paramsToSend.filter = JSON.stringify(params?.filter) : undefined;

	const options = buildDefaultOptions(accessToken);
	const queryString = buildURLQuery(paramsToSend);
	const URL = `${AFFECTATION_URI}?${queryString}`;

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

//balance transfer
export const postBalanceTransfer = async( accessToken, body) => {
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpPost( BALANCE_TRANSFER_URI, body, options);
	}
	catch(error) {
		return error;
	}

	return response;
};