import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { DropdownSubmenu } from "react-bootstrap-submenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import NavDropdownItem from './NavDropdownItem';
import { usePermissions } from './hooks/usePermissions';
import { ROUTE_PATHS } from 'src/routes';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getExercisesListData } from 'src/redux/exercise/exerciseReducer';
import { getStatusApi } from 'src/redux/login/loginActionCreator';
import { tryGetActiveAlert } from 'src/redux/alert/alertActionCreator';
import { useProfileChecks } from './hooks/useProfileChecks';

const MenuBudgetExecution = () => {

	const dispatch = useDispatch();

    const {
        profileName,
        executionBudgetPermissions,
        paymentOrdersPermissions,
        paramsToAlerts
    } = usePermissions();
    const { 
        isProfileNameSA,
        isProfileNameAI,
        isProfileBudgetServiceOperator,
        isProfileDirectorSa,
        isProfileChiefAccountantSA,
        isProfileBudgetDirection,
        isProfileBudgetEmployee
    } = useProfileChecks();

	const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
	const exercisesListData = useSelector(state => getExercisesListData(state));

	const isAvailableShowActionsRp = value => {
    	const todayDate = moment(new Date()).format('YYYY-MM-DD');
        const dateToComplementary = exercisesListData?.records?.filter(item => item?.year === globalSelectedPeriod?.year)[0]?.periods?.filter(item => item?.name === 'COMPLEMENTARIO')[0]?.startDate;
        const endDateComplementary = exercisesListData?.records?.filter(item => item?.year === globalSelectedPeriod?.year)[0]?.periods?.filter(item => item?.name === 'COMPLEMENTARIO')[0]?.endDate;
		let isValidYear = globalSelectedPeriod?.year == value
		let isStartDate = todayDate >=  moment(dateToComplementary).format('YYYY-MM-DD')
		let isEndDate = endDateComplementary >= todayDate
		let isAvailableYearActionsRP = isValidYear && isStartDate && isEndDate
		return isAvailableYearActionsRP;
	}

    // TODO: Se utiliza al mostrar el menu de Reversion de Residuos Pasivos
    const isAvailableShowRevertPassiveRemaining = (exercise) => {
		let isAvailable = false;
		const previousExercise = exercise - 1;
		const previousExerciceData = exercisesListData?.records?.filter(item => item?.year == previousExercise)[0];
		const complementaryPeriod = previousExerciceData?.periods?.filter(item => item?.name == 'COMPLEMENTARIO')[0];
		const startOfComplementaryPeriod = complementaryPeriod?.startDate;
		const endtOfComplementaryPeriod = complementaryPeriod?.endDate;
		if(startOfComplementaryPeriod != undefined && endtOfComplementaryPeriod != undefined){
			isAvailable = (todayDate >=  moment(startOfComplementaryPeriod).format('YYYY-MM-DD') && todayDate <=  moment(endtOfComplementaryPeriod).format('YYYY-MM-DD')) ? true : false;
		};
		return isAvailable;
	};

    if (!executionBudgetPermissions?.canView) return null;

    return (
        <NavDropdown 
            className='active' 
            title={<><FontAwesomeIcon icon={faBalanceScale} className='mr-1'/>Ejecución presupuesto</>}
            onClick={() => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]}
        >
            {executionBudgetPermissions?.canView && 
                <NavDropdownItem route={ROUTE_PATHS.CREDIT_EXECUTION} title="Partidas presupuestarias" />
            }
            {paymentOrdersPermissions?.canView && 
                <NavDropdownItem route={ROUTE_PATHS.ORDER_PAY_LIST} title="Orden pago" />
            }
            {((isProfileNameSA || isProfileNameAI || isProfileBudgetServiceOperator || isProfileDirectorSa || isProfileChiefAccountantSA) 
                    && (isAvailableShowActionsRp(2022) || isAvailableShowActionsRp(2023) || isAvailableShowActionsRp(2024))
                ) && 
                <NavDropdownItem route={ROUTE_PATHS.REPORT_CONVERTION} title="Residuos Pasivos por Servicio" />
            }
            {(isProfileNameSA || isProfileBudgetDirection || isProfileBudgetEmployee) &&
                <NavDropdownItem route={ROUTE_PATHS.PRE_LOAD} title="Precarga" />
            }
            {/* { // TODO: DESCOMENTAR AL TERMINAR REVERSIONES
             ((isProfileNameSA) && (isAvailableShowRevertPassiveRemaining(globalSelectedPeriod?.year))) &&
                <NavDropdownItem route={ROUTE_PATHS.REVERT_PASSIVE_REMAINING_BY_SERVICE} title="Reversión de Residuos Pasivos" />
            } */}
            {paymentOrdersPermissions?.canView &&
                <DropdownSubmenu href="" title="Configuración Presupuestaria">
                    <NavDropdownItem route={ROUTE_PATHS.CONCEPT_LIST} title="• Conceptos de Haberes" />
                    <NavDropdownItem route={ROUTE_PATHS.WAGES_TEMPLATE_LIST} title="• Plantilla de Haberes" />
                </DropdownSubmenu>	
            }
            {executionBudgetPermissions?.canView && 
                <NavDropdownItem route={ROUTE_PATHS.BALANCE_TRANSFER_STEP_ONE} title="Traspaso de saldos de RP" />
            }
            {executionBudgetPermissions?.canView && 
                <NavDropdownItem route={ROUTE_PATHS.DISAFFECTATIONS_STEP_ONE} title="Movimientos en etapas de partidas de RP" />
            }
        </NavDropdown>
    );
};

export default MenuBudgetExecution;