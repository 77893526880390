import React, { useEffect } from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import StepIndicator from 'src/components/common/StepIndicator';
import { getNewAffectationAdministrativeDocumentData, getNewAffectationSelectedCredits } from 'src/redux/affectation/affectationReducer';
import { parseIntOrUndefined } from 'src/utils/utils';
import { DISAFFECTATIONS_STEP_ONE, DISAFFECTATIONS_STEP_THREE } from 'src/utils/constants';
import { useForm, FormProvider } from 'react-hook-form';
import { tryGetAffectationBalancesOfSelectedCredits } from 'src/redux/affectation/affectationActionCreator';
import { getSubpartialBudgetOfSelectedCreditsIsFetching } from 'src/redux/partialBudget/partialBudgetReducer';
import { isNotEmptyArray } from 'src/services/validationService';
import { clearLatestAffectationTransactionData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import BudgetItems from 'src/components/pages/BudgetItems';
import SelectedBudgetItems from 'src/components/pages/CreditExecution/SelectedBudgetItems';

const BalanceIncreaseStepTwo = () => {
	const dispatch = useDispatch();
	const hookFormMethods = useForm();
	const selectedCredits = useSelector(state => getNewAffectationSelectedCredits(state));
	const administrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));
	const administrativeDocumentData = { ...administrativeDocument, organizationCode: parseIntOrUndefined(administrativeDocument?.organizationCode) };
	const showExpedientCheck = true;
	const hasSelectedCredits = isNotEmptyArray(selectedCredits);
	const enableNextStepButton = hasSelectedCredits;
	const isFetchingSubpartialBudgetOfSelectedCredits = useSelector(state => getSubpartialBudgetOfSelectedCreditsIsFetching(state));
	
	const onClickBackButton = () => {
		dispatch(push(DISAFFECTATIONS_STEP_ONE));
	};

	const onClickNextStepButton = () => {
		dispatch(push(DISAFFECTATIONS_STEP_THREE));
		const allPromises = [];
		allPromises.push(dispatch(tryGetAffectationBalancesOfSelectedCredits(selectedCredits, administrativeDocumentData)));
		Promise.all(allPromises)
			.then(() => { dispatch(push(DISAFFECTATIONS_STEP_THREE)) });
		dispatch(clearLatestAffectationTransactionData());
	};

	useEffect(() => {
        if(!administrativeDocument){
			dispatch(push(DISAFFECTATIONS_STEP_ONE));
		}
    }, []);

	return <Container fluid >
		<Card className='mb-5'>
			<Card.Header className='d-flex justify-content-between'>
                <h1 className="h6 mt-1 mb-0">Movimientos en etapas de partidas de RP</h1>
            </Card.Header>
			<StepIndicator steps={config.appSettings.steps.balanceTransfer} current={1} className='mt-2 mb-3' />
			<Card.Header className='h6 mx-3'>
				Movimientos en etapas de partidas de RP
			</Card.Header>
			<Card.Body className="mx-3">
				<FormProvider {...hookFormMethods} >
					<Form onSubmit={(e) => {e.preventDefault()}}>
						<BudgetItems
							isFetchingSubpartialBudgetOfSelectedCredits={isFetchingSubpartialBudgetOfSelectedCredits}
							showExpedientCheck={showExpedientCheck}
							passiveRemainingOnly={true}
							associatedExpedientOnly={true}
						/>
						<SelectedBudgetItems />
						<Form.Group className='mt-4 d-flex justify-content-around'>
							<Button
								className='text-white-color btn-primary btn-lg'
								onClick={onClickBackButton}
								disabled={isFetchingSubpartialBudgetOfSelectedCredits}
							>
								<FontAwesomeIcon className='mr-3' icon={faArrowLeft} />
								Volver
							</Button>
							<span className={isFetchingSubpartialBudgetOfSelectedCredits ? '' : 'hidden'}>
								<Spinner animation='border' />
							</span>
							<Button
								className='text-white-color btn-success btn-lg'
								onClick={onClickNextStepButton}
								disabled={!enableNextStepButton || isFetchingSubpartialBudgetOfSelectedCredits}
							>
								Siguiente
								<FontAwesomeIcon className='ml-3' icon={faArrowRight} />
							</Button>
						</Form.Group>
					</Form>
				</FormProvider>
			</Card.Body>
		</Card>
	</Container>;
};

export default BalanceIncreaseStepTwo;
