import React, { useState, useEffect } from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, Card, Form, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import StepIndicator from 'src/components/common/StepIndicator';
import { getAdministrativeDocumentIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';
import { BALANCE_TRANSFER_STEP_TWO, INITIAL_URI } from 'src/utils/constants';
import AssociatedExpedientComponent from 'src/components/forms/balanceTransfer/AssociatedExpedientComponent';
import { clearNewAffectationDataSelectedCredits } from 'src/redux/affectation/affectationActions';

const BalanceTransferStepOne = () => {
    const dispatch = useDispatch();
    const hookFormMethods = useForm();
    const administrativeDocumentDataFromEndpointIsFetching = useSelector(state => getAdministrativeDocumentIsFetching(state));
    const selectedAdministrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));
    const [dataLengthCharacter, setDataLengthCharacter] = useState(false);
    const [dataYearSelected, setDataYearSelected] = useState(false);
    const [dataNumberSelected, setDataNumberSelected] = useState(false);
    const [isNewAdministrativeDocument, setIsNewAdministrativeDocument] = useState(true);

    const onClickNextStepButton = () => {
        dispatch(push(BALANCE_TRANSFER_STEP_TWO))
    };

    const onClickCancelButton = () => {
        dispatch(push(INITIAL_URI));
    };

    const enableButton = (selectedAdministrativeDocument && !administrativeDocumentDataFromEndpointIsFetching);

    useEffect(() => {
        dispatch(clearNewAffectationDataSelectedCredits());
    }, []);

    return <Container fluid >
        <Card className='mb-5'>
            <Card.Header className='d-flex justify-content-between'>
                <h1 className="h6 mt-1 mb-0">Partidas presupuestarias</h1>
            </Card.Header>
            <StepIndicator steps={config.appSettings.steps.balanceTransfer} current={0} className='mt-2 mb-3' />
            <Container fluid >
                <Card.Header className='h6'>
                    Traspaso de Saldos de RP
                </Card.Header>
                <Card.Body>
                    <h6 className='text-black-color mb-3'>Datos del expediente asociado</h6>
                    <span className='text-danger d-flex mandatory-label text-right font-weight-bold font-italic'>
                        <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                        Obligatorio
                    </span>
                    <FormProvider {...hookFormMethods} >
                        <Form className='text-black-color mt-5 mb-5'>
                            <AssociatedExpedientComponent
                                dataLengthCharacter={dataLengthCharacter}
                                setDataLengthCharacter={setDataLengthCharacter}
                                dataYearSelected={dataYearSelected}
                                setDataYearSelected={setDataYearSelected}
                                dataNumberSelected={dataNumberSelected}
                                setDataNumberSelected={setDataNumberSelected}
                                isNewAdministrativeDocument={isNewAdministrativeDocument}
                                setIsNewAdministrativeDocument={setIsNewAdministrativeDocument}
                            />
                            <div className='container-fluid py-4 d-flex justify-content-around text-white-color'>
                                <Button
                                    className='text-white-color btn-primary btn-lg'
                                    tabIndex='6'
                                    onClick={onClickCancelButton}
                                >
                                    Cancelar
                                </Button>
                                <Spinner
                                    animation='border'
                                    className={administrativeDocumentDataFromEndpointIsFetching ? '' : 'hidden'}
                                />
                                <Button
                                    className='text-white-color btn-success btn-lg'
                                    tabIndex='5' 
                                    onClick={onClickNextStepButton}
                                    disabled={!enableButton}
                                >
                                    Siguiente
                                    <FontAwesomeIcon className='ml-3' icon={faArrowRight} />
                                </Button>
                            </div>
                        </Form>
                    </FormProvider>
                </Card.Body>
            </Container>
        </Card>
    </Container>;
};

export default BalanceTransferStepOne;