export const SET_NEW_AFFECTATION_DATA_SELECTED_CREDITS = 'SET_NEW_AFFECTATION_DATA_SELECTED_CREDITS';
export const SET_NEW_AFFECTATION_ADMINISTRATIVE_DOCUMENT_DATA = 'SET_NEW_AFFECTATION_ADMINISTRATIVE_DOCUMENT_DATA';
export const SET_AFFECTATION_HISTORY_DATA_CREDIT_SELECTED = 'SET_AFFECTATION_HISTORY_DATA_CREDIT_SELECTED';
export const SET_CREDIT_QUERY_DATA_CREDIT_SELECTED = 'SET_CREDIT_QUERY_DATA_CREDIT_SELECTED';

export const CLEAR_NEW_AFFECTATION_DATA_SELECTED_CREDITS = 'CLEAR_NEW_AFFECTATION_DATA_SELECTED_CREDITS';
export const CLEAR_NEW_AFFECTATION_ADMINISTRATIVE_DOCUMENT_DATA = 'CLEAR_NEW_AFFECTATION_ADMINISTRATIVE_DOCUMENT_DATA';
export const CLEAR_AFFECTATION_HISTORY_DATA_CREDIT_SELECTED = 'CLEAR_AFFECTATION_HISTORY_DATA_CREDIT_SELECTED';
export const CLEAR_CREDIT_QUERY_DATA_CREDIT_SELECTED = 'CLEAR_CREDIT_QUERY_DATA_CREDIT_SELECTED';

export const GET_AFFECTATION_TYPES_REQUEST = 'GET_AFFECTATION_TYPES_REQUEST';
export const GET_AFFECTATION_TYPES_REQUEST_SUCCESS = 'GET_AFFECTATION_TYPES_REQUEST_SUCCESS';
export const GET_AFFECTATION_TYPES_REQUEST_FAILURE = 'GET_AFFECTATION_TYPES_REQUEST_FAILURE';

export const CREATE_AFFECTATION_REQUEST = "CREATE_AFFECTATION_REQUEST";
export const CREATE_AFFECTATION_REQUEST_SUCCESS = "CREATE_AFFECTATION_REQUEST_SUCCESS";
export const CREATE_AFFECTATION_REQUEST_FAILURE = "CREATE_AFFECTATION_REQUEST_FAILURE";

export const CREATE_DISAFFECTION_REQUEST = "CREATE_DISAFFECTION_REQUEST";
export const CREATE_DISAFFECTION_REQUEST_SUCCESS = "CREATE_DISAFFECTION_REQUEST_SUCCESS";
export const CREATE_DISAFFECTION_REQUEST_FAILURE = "CREATE_DISAFFECTION_REQUEST_FAILURE";

export const CREATE_CONVERSION_TO_PASSIVE_REMAINING_REQUEST = "CREATE_CONVERSION_TO_PASSIVE_REMAINING_REQUEST";
export const CREATE_CONVERSION_TO_PASSIVE_REMAINING_REQUEST_SUCCESS = "CREATE_CONVERSION_TO_PASSIVE_REMAINING_REQUEST_SUCCESS";
export const CREATE_CONVERSION_TO_PASSIVE_REMAINING_REQUEST_FAILURE = "CREATE_CONVERSION_TO_PASSIVE_REMAINING_REQUEST_FAILURE";

//Reverse passive remaining
export const REVERSE_PASSIVE_REMAINING_REQUEST = "REVERSE_PASSIVE_REMAINING_REQUEST";
export const REVERSE_PASSIVE_REMAINING_REQUEST_SUCCESS = "REVERSE_PASSIVE_REMAINING_REQUEST_SUCCESS";
export const REVERSE_PASSIVE_REMAINING_REQUEST_FAILURE = "REVERSE_PASSIVE_REMAINING_REQUEST_FAILURE"

//Revert passive remaining by service
export const REVERT_PASSIVE_REMAINING_BY_SERVICE_REQUEST = "REVERT_PASSIVE_REMAINING_BY_SERVICE_REQUEST";
export const REVERT_PASSIVE_REMAINING_BY_SERVICE_REQUEST_SUCCESS = "REVERT_PASSIVE_REMAINING_BY_SERVICE_REQUEST_SUCCESS";
export const REVERT_PASSIVE_REMAINING_BY_SERVICE_REQUEST_FAILURE = "REVERT_PASSIVE_REMAINING_BY_SERVICE_REQUEST_FAILURE"

export const CREATE_CONVERSION_TO_PASSIVE_REMAINING_BY_SERVICE_REQUEST = "CREATE_CONVERSION_TO_PASSIVE_REMAINING_BY_SERVICE_REQUEST";
export const CREATE_CONVERSION_TO_PASSIVE_REMAINING_BY_SERVICE_REQUEST_SUCCESS = "CREATE_CONVERSION_TO_PASSIVE_REMAINING_BY_SERVICE_REQUEST_SUCCESS";
export const CREATE_CONVERSION_TO_PASSIVE_REMAINING_BY_SERVICE_REQUEST_FAILURE = "CREATE_CONVERSION_TO_PASSIVE_REMAINING_BY_SERVICE_REQUEST_FAILURE";

// Affectation transactions (Endpoint: /v1/expenses/affectation-transactions)
export const GET_AFFECTATION_TRANSACTIONS_REQUEST = 'GET_AFFECTATION_TRANSACTIONS_REQUEST';
export const GET_AFFECTATION_TRANSACTIONS_REQUEST_SUCCESS = 'GET_AFFECTATION_TRANSACTIONS_REQUEST_SUCCESS';
export const GET_AFFECTATION_TRANSACTIONS_REQUEST_FAILURE = 'GET_AFFECTATION_TRANSACTIONS_REQUEST_FAILURE';
export const CLEAR_AFFECTATION_TRANSACTIONS = 'CLEAR_AFFECTATION_TRANSACTIONS';

// Get availables affectation status by subcode
export const GET_AVAILABLES_AFFECTATION_STATUS_REQUEST = 'GET_AVAILABLES_AFFECTATION_STATUS_REQUEST';
export const GET_AVAILABLES_AFFECTATION_STATUS_REQUEST_SUCCESS = 'GET_AVAILABLES_AFFECTATION_STATUS_REQUEST_SUCCESS';
export const GET_AVAILABLES_AFFECTATION_STATUS_REQUEST_FAILURE = 'GET_AVAILABLES_AFFECTATION_STATUS_REQUEST_FAILURE';

// Get availables affectations status by subcode of selected credits
export const GET_AVAILABLES_AFFECTATION_STATUS_OF_SELECTED_CREDITS_REQUEST = 'GET_AVAILABLES_AFFECTATION_STATUS_OF_SELECTED_CREDITS_REQUEST';
export const GET_AVAILABLES_AFFECTATION_STATUS_OF_SELECTED_CREDITS_REQUEST_SUCCESS = 'GET_AVAILABLES_AFFECTATION_STATUS_OF_SELECTED_CREDITS_REQUEST_SUCCESS';
export const GET_AVAILABLES_AFFECTATION_STATUS_OF_SELECTED_CREDITS_REQUEST_FAILURE = 'GET_AVAILABLES_AFFECTATION_STATUS_OF_SELECTED_CREDITS_REQUEST_FAILURE';

//Get availables affectations history transactions by id
export const GET_AVAILABLES_AFFECTATION_HISTORY_TRANSACTION_BY_ID_REQUEST = 'GET_AVAILABLES_AFFECTATION_HISTORY_TRANSACTION_BY_ID_REQUEST';
export const GET_AVAILABLES_AFFECTATION_HISTORY_TRANSACTION_BY_ID_REQUEST_SUCCESS = 'GET_AVAILABLES_AFFECTATION_HISTORY_TRANSACTION_BY_ID_REQUEST_SUCCESS';
export const GET_AVAILABLES_AFFECTATION_HISTORY_TRANSACTION_BY_ID_REQUEST_FAILURE = 'GET_AVAILABLES_AFFECTATION_HISTORY_TRANSACTION_BY_ID_REQUEST_FAILURE';

// Get affectation by subcode
export const GET_AFFECTATION_SUBCODE_REQUEST = 'GET_AFFECTATION_SUBCODE_REQUEST';
export const GET_AFFECTATION_SUBCODE_REQUEST_SUCCESS = 'GET_AFFECTATION_SUBCODE_REQUEST_SUCCESS';
export const GET_AFFECTATION_SUBCODE_REQUEST_FAILURE = 'GET_AFFECTATION_SUBCODE_REQUEST_FAILURE';

// Get balances by subcode of selected credits
export const GET_BALANCES_OF_SELECTED_CREDITS_REQUEST = 'GET_BALANCES_OF_SELECTED_CREDITS_REQUEST';
export const GET_BALANCES_OF_SELECTED_CREDITS_REQUEST_SUCCESS = 'GET_BALANCES_OF_SELECTED_CREDITS_REQUEST_SUCCESS';
export const GET_BALANCES_OF_SELECTED_CREDITS_REQUEST_FAILURE = 'GET_BALANCES_OF_SELECTED_CREDITS_REQUEST_FAILURE';

// set date affectation actions
export const SET_DATE_AFFECTARION_DATA = 'SET_DATE_AFFECTARION_DATA';
export const CLEAR_DATE_AFFECTARION_DATA = 'CLEAR_DATE_AFFECTARION_DATA';

// set extract affectation actions
export const SET_EXTRACT_AFFECTATION_DATA = 'SET_EXTRACT_AFFECTATION_DATA';
export const CLEAR_EXTRACT_AFFECTATION_DATA = 'CLEAR_EXTRACT_AFFECTATION_DATA';

// set revert affectation
export const SET_REVERT_AFFECTATION = 'SET_REVERT_AFFECTATION';

// create balance transfer
export const CREATE_BALANCE_TRANSFER_REQUEST = "CREATE_BALANCE_TRANSFER_REQUEST";
export const CREATE_BALANCE_TRANSFER_REQUEST_SUCCESS = "CREATE_BALANCE_TRANSFER_REQUEST_SUCCESS";
export const CREATE_BALANCE_TRANSFER_REQUEST_FAILURE = "CREATE_BALANCE_TRANSFER_REQUEST_FAILURE";