import React, { useEffect, useState } from 'react';
import { config } from 'src/env.js';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card, Alert, Form, Table, Row, Col, Button, Carousel } from 'react-bootstrap';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getNewAffectationSelectedCredits, getNewAffectationAdministrativeDocumentData, getNewAffectationData, getNewBalanceTransferIsFetching } from 'src/redux/affectation/affectationReducer';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { isNotEmptyArray  } from 'src/services/validationService';
import StepIndicator from 'src/components/common/StepIndicator';
import { DISAFFECTATIONS_STEP_FOUR, DISAFFECTATIONS_STEP_TWO } from 'src/utils/constants';
import { formatterPeso, numberNegativeRed } from 'src/utils/utils';
import { cancel, accept } from 'src/utils/label';
import { setNewAffectationDataSelectedCredits } from 'src/redux/affectation/affectationActions';
import { showWarning, showError } from 'src/redux/globalData/globalDataActionCreator';
import { NumericFormat } from 'react-number-format';
import { tryPostBalanceTransfer } from 'src/redux/affectation/affectationActionCreator';
import AppLoading from 'src/components/common/AppLoading';

const BalanceIncreaseStepThree = () => {
    const dispatch = useDispatch();
    const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const administrativeDocumentData = useSelector(state => getNewAffectationAdministrativeDocumentData(state));

    const withValueLimit = (inputObj) => {
        const { value } = inputObj;
        const maxValue = 999999999999.99;
        const minValue = -999999999999.99;
        if (value <= maxValue && value >= minValue || value == '-') return inputObj;
    };

    // Credits
    const selectedSubcodes = useSelector(state => getNewAffectationSelectedCredits(state))?.map(item => {
        if (!item?.hasOwnProperty('balanceTransferArray')) {
            item.balanceTransferArray = []
        }
        return item;
    });

    const hasSelectedSubcodes = isNotEmptyArray(selectedSubcodes);
    const numberOfSelectedSubcodes = selectedSubcodes?.length;
    const newAffectationData = useSelector(state => getNewAffectationData(state));
    const newBalanceTransferIsFetching = useSelector(state => getNewBalanceTransferIsFetching(state));

    // Carousel
    const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
    const handleCarouselSelect = selectedIndex => {
        setCarouselActiveIndex(selectedIndex);
    };

    const onClickNextCarouselItem = () => {
        handleCarouselSelect(carouselActiveIndex + 1);
    };

    const onClickBackCarouselItem = () => {
        handleCarouselSelect(carouselActiveIndex - 1);
    };

    const [errorInSlide, setErrorInSlide] = useState();
    const setErrorInCreditSlide = responseData => {
        const subcodeIdWithError = responseData?.reference;
        const errorMessage = responseData?.message;
        const errorObjToSet = {
            subcodeId: subcodeIdWithError,
            message: errorMessage,
        };
        setErrorInSlide(errorObjToSet);

        // Set visible subcode slide with error
        const subcodeIndex = searchSubcodeCarouselIndex(subcodeIdWithError);
        handleCarouselSelect(subcodeIndex);
    };

    //Actions
    const organizationCode = newAffectationData?.administrativeDocument?.organizationCode;
    const organizationNumber = newAffectationData?.administrativeDocument?.number;
    const organizationYear = newAffectationData?.administrativeDocument?.year;
    const id = organizationCode.toString() + organizationNumber.toString() + organizationYear.toString();
    const addExpedientToList = (subCodeData) => {
        const existingExpedient = subCodeData?.balanceTransferArray.filter(item => item?.id == id).length > 0;
        if (!existingExpedient) {
            const organizationObj = {
                id,
                organization_code: organizationCode,
                organization_number: organizationNumber,
                organization_year: organizationYear,
                amount: undefined
            };
            let newCredits = selectedSubcodes.map(item => {
                if (item?.id == subCodeData?.id) {
                    item.balanceTransferArray = [...item?.balanceTransferArray, organizationObj]
                }
                return item;
            });
            dispatch(setNewAffectationDataSelectedCredits(newCredits));
        };
    };

    const onClickRemoveExpedeint = (subCodeData, id) => {
        const newExpedienstArray = subCodeData?.balanceTransferArray?.filter(item => item?.id != id);
        let newCredits = selectedSubcodes.map(item => {
            if (item?.id == subCodeData?.id) {
                item.balanceTransferArray = newExpedienstArray
            }
            return item;
        });
        dispatch(setNewAffectationDataSelectedCredits(newCredits));
    };

    const onClickBackButton = () => {
        dispatch(push(DISAFFECTATIONS_STEP_TWO));
    };

    const validAmounts = () => {
        let valid = true;
        selectedSubcodes?.map(item => {
            const balanceTransferArray = item?.balanceTransferArray;
            if(balanceTransferArray?.length >= 1){
                balanceTransferArray?.map(element => {
                    if (isNaN(element?.amount) || element?.amount == 0) {
                        valid = false;
                    }
                })
            } else {
                valid = false;
            }
        });
        return valid;
    };

    const onClickNextStepButton = () => {
        if (!validAmounts()) {
            showWarning(`Debe cargar todos los importes correctamente.`);
        } else {
            swal({
                title: "Confirmación de nuevo Incremento de Saldos.",
                text: "¿Seguro que quiere realizar la operación?",
                icon: 'warning',
                buttons: [cancel, accept]
            }).then((userAccept) => {
                if (userAccept) {
                    submitTransferOfBalances();
                }
            });
        }
    };

    const submitTransferOfBalances = () => {
        const objectToSend = {
            exercise: selectedPeriod?.year,
            action: "modify",
            sourceAdministrativeDocument: {
                organization_number: administrativeDocumentData?.organizationCode,
                number: administrativeDocumentData?.number,
                year: administrativeDocumentData?.year
            },
            targetTransactions: []
        };
        if (hasSelectedSubcodes) {
            objectToSend.targetTransactions = selectedSubcodes.map(item => {
                return {
                    subCodeId: item?.id,
                    amount: item?.balanceTransferArray?.reduce((acc, element) => acc + parseFloat(element?.amount || 0), 0),
                };
            });
        };
        dispatch(tryPostBalanceTransfer(objectToSend)).then(response => {
            if (response?.status == 200) {
                dispatch(push(DISAFFECTATIONS_STEP_FOUR));
            } else if (response?.status == 422) {
                setErrorInCreditSlide(response?.data);
                showError(response?.data?.message);
            } else {
                showError(response?.data?.message);
            }
        });
    };

    const onChangeAffectationAmount = (inputObject, subCodeData, expedientId) => {
        let newCredits = selectedSubcodes.map(item => {
            if (item?.id == subCodeData?.id) {
                item?.balanceTransferArray?.map((expItem) => {
                    if (expItem?.id == expedientId) {
                        expItem.amount = inputObject?.value
                    };
                    return expItem;
                })
            }
            return item;
        });
        dispatch(setNewAffectationDataSelectedCredits(newCredits));
    };

    useEffect(() => {
        selectedSubcodes?.map(subcodeData => {
            addExpedientToList(subcodeData);
        });
    }, [organizationCode, organizationNumber, organizationYear]);

    return (
        <Container fluid >
            <Card className='mb-5'>
                <Card.Header className='d-flex justify-content-between'>
                    <h1 className="h6 mt-1 mb-0">Movimientos en etapas de partidas de RP</h1>
                </Card.Header>
                <StepIndicator steps={config.appSettings.steps.balanceTransfer} current={2} className='mt-2 mb-3' />
                <Card className='mx-3 mb-3'>
                    <Card.Header className='h6'>
                        Movimientos en etapas de partidas de RP
                    </Card.Header>
                    <Card.Body>
                        {hasSelectedSubcodes ?
                            <>
                                <Card className='shadow mb-3' style={{ borderColor: 'gray', borderRadius: '2px' }}>
                                    <Card.Body className='bg-white'>
                                        <Carousel interval={null} indicators={false} controls={false} activeIndex={carouselActiveIndex} onSelect={handleCarouselSelect} fade>
                                            {selectedSubcodes?.map(subcodeData => {
                                                return (
                                                    <Carousel.Item key={subcodeData?.id}>
                                                        <Form.Group className='text-black-color' as={Row}>
                                                            <Col sm='6'>
                                                                <p className='h6 mb-3'>DETALLES DE LA PARTIDA</p>
                                                            </Col>
                                                            <Col sm='6'>
                                                                <p className='h6 mb-3' >DETALLES DEL EXPEDIENTE</p>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group className='text-black-color' as={Row}>
                                                            <Col sm='6' >
                                                                <Table striped borderless hover>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className='text-left' md={4}>Nº de partida:</td>
                                                                            <td className='text-right' md={4} md-offset={8}>
                                                                                {subcodeData?.completeNumber}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-left' md={4}>Denominación de Partida:</td>
                                                                            <td className='text-right' md={8}>
                                                                                {subcodeData?.visibleName}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-left font-weight-bold' md={4}>Saldo de la partida:</td>
                                                                            <td className={`${numberNegativeRed(subcodeData?.balance?.currentBalance)} + text-right font-weight-bold`} md={8}>
                                                                                {formatterPeso.format(subcodeData?.balance?.currentBalance)}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-left font-weight-bold' md={4}>Saldo Preventiva:</td>
                                                                            <td className={`${numberNegativeRed(subcodeData?.balances?.totals?.preventive)} + text-right font-weight-bold`} md={8}>
                                                                                {!isNaN(subcodeData?.balances?.totals?.preventive) 
                                                                                    ? formatterPeso.format(subcodeData?.balances?.totals?.preventive)
                                                                                    : '$ 0,00'
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-left font-weight-bold' md={4}>Saldo Definitiva:</td>
                                                                            <td className={`${numberNegativeRed(subcodeData?.balances?.totals?.definitive)} + text-right font-weight-bold`} md={8}>
                                                                                {!isNaN(subcodeData?.balances?.totals?.definitive)
                                                                                    ? formatterPeso.format(subcodeData?.balances?.totals?.definitive)
                                                                                    : '$ 0,00'
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-left font-weight-bold' md={4}>Saldo obl. Pago:</td>
                                                                            <td className={`${numberNegativeRed(subcodeData?.balances?.totals?.payment_obligation)} + text-right font-weight-bold`} md={8}>
                                                                                {!isNaN(subcodeData?.balances?.totals?.payment_obligation)
                                                                                    ? formatterPeso.format(subcodeData?.balances?.totals?.payment_obligation)
                                                                                    : '$ 0,00'
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-left font-weight-bold' md={4}>Saldo Pago:</td>
                                                                            <td className={`${numberNegativeRed(subcodeData?.balances?.totals?.payment)} + text-right font-weight-bold`} md={8}>
                                                                                {!isNaN(subcodeData?.balances?.totals?.payment) 
                                                                                    ? formatterPeso.format(subcodeData?.balances?.totals?.payment)
                                                                                    : '$ 0,00'
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </Col>
                                                            <Col sm='6'>
                                                                <Table striped borderless hover>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className='text-left' md={4}>Código de Organismo:</td>
                                                                            <td className='text-right' md={8}>
                                                                                {newAffectationData?.administrativeDocument?.organizationCode}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-left' md={4}>Número:</td>
                                                                            <td className='text-right' md={8}>
                                                                                {newAffectationData?.administrativeDocument?.number}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-left' md={4}>Año:</td>
                                                                            <td className='text-right' md={8}>
                                                                                {newAffectationData?.administrativeDocument?.year}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-left' md={4}>Título:</td>
                                                                            <td className='text-right' md={8}>
                                                                                {newAffectationData?.administrativeDocument?.title}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </Col>
                                                        </Form.Group>
                                                        <hr className='my-4' />
                                                        <p className='h6 mb-4 text-black-color'>Desafectación o incremento</p>
                                                        <Row className="justify-content-md-center">
                                                            {subcodeData?.balanceTransferArray?.length > 0 ?
                                                                <Col sm='10'>
                                                                    <Table striped bordered hover size='sm'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th className='text-center' style={{ "width": "22%" }}>Expediente</th>
                                                                                <th className='text-center' style={{ "width": "22%" }}>Número</th>
                                                                                <th className='text-center' style={{ "width": "22%" }}>Año</th>
                                                                                <th className='text-center' style={{ "width": "22%" }}>Monto</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className='text-black-color'>
                                                                            {subcodeData?.balanceTransferArray?.map(item => (
                                                                                <tr key={item?.id}>
                                                                                    <td className='text-center'>
                                                                                        {item?.organization_code}
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        {item?.organization_number}
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        {item?.organization_year}
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        <NumericFormat
                                                                                            className='form-control'
                                                                                            decimalScale={2}
                                                                                            thousandSeparator='.'
                                                                                            decimalSeparator=','
                                                                                            inputMode='numeric'
                                                                                            value={item?.amount}
                                                                                            defaultValue={item?.amount}
                                                                                            allowLeadingZeros={false}
                                                                                            isAllowed={inputObj => withValueLimit(inputObj)}
                                                                                            onValueChange={inputObject => onChangeAffectationAmount(inputObject, subcodeData, item?.id)}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </Col> 
                                                                : null
                                                            }
                                                        </Row>
                                                        {(errorInSlide && errorInSlide?.subcodeId == subcodeData?.id) ?
                                                            <Row>
                                                                <Col>
                                                                    <Alert variant='danger' className='mb-0'>
                                                                        {errorInSlide?.message}
                                                                    </Alert>
                                                                </Col>
                                                            </Row>
                                                            : null
                                                        }
                                                    </Carousel.Item>
                                                );
                                            })}
                                        </Carousel>
                                    </Card.Body>
                                </Card>
                                <Form.Group className='d-flex justify-content-center align-items-center'>
                                    <Button size='xs' onClick={onClickBackCarouselItem} disabled={(carouselActiveIndex == 0)} className='nav-btn'>
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </Button>
                                    <span className='px-3 text-black-color'>
                                        {`Partida ${carouselActiveIndex + 1}/${numberOfSelectedSubcodes}`}
                                    </span>
                                    <Button size='xs' onClick={onClickNextCarouselItem} disabled={(carouselActiveIndex == (numberOfSelectedSubcodes - 1))} className='nav-btn'>
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </Button>
                                </Form.Group>
                            </>
                            : null
                        }
                        <Form.Group className='mt-4 d-flex justify-content-around'>
                            <Button
								className='text-white-color btn-primary btn-lg'
                                onClick={onClickBackButton}
                            >
                                <FontAwesomeIcon className='mr-3' icon={faArrowLeft} />
                                Volver
                            </Button>
                            <Button
								className='text-white-color btn-success btn-lg'
                                onClick={onClickNextStepButton}
                                disabled={newBalanceTransferIsFetching}
                            >
                                Guardar
                            </Button>
                        </Form.Group>
                        <AppLoading isLoading={newBalanceTransferIsFetching} />
                    </Card.Body>
                </Card>
            </Card>
        </Container>
    )
};

export default BalanceIncreaseStepThree;