import React from 'react';
import { config } from 'src/env.js';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import StepIndicator from 'src/components/common/StepIndicator';
import { BALANCE_TRANSFER_STEP_ONE, CREDIT_EXECUTION } from 'src/utils/constants';
import { clearNewAffectationDataSelectedCredits } from 'src/redux/affectation/affectationActions';

const BalanceIncreaseStepFour = () => {
	const dispatch = useDispatch();

	const onClickGoToCreditExecution = () => {
		dispatch(push(CREDIT_EXECUTION))
	};

	const onClickNewAffectation = () => {
		dispatch(clearNewAffectationDataSelectedCredits());
		dispatch(push(BALANCE_TRANSFER_STEP_ONE));
	};

	return (
	<Container fluid >
		<Card className='mb-5'>
			<Card.Header className='d-flex justify-content-between'>
				<h1 className="h6 mt-1 mb-0">Movimientos en etapas de partidas de RP</h1>
			</Card.Header>
			<StepIndicator steps={config.appSettings.steps.balanceTransfer} current={3} className='mt-2 mb-3' />
			<Card className='mx-3 mb-3'>
				<Card.Header className='h6'>
					Desafectacion o incrementos de saldos
				</Card.Header>
				<Card.Body>
					<h5 className='text-main-color text-center mt-4 mb-5'>
						¡Nuevo/s desafectación/s o incremento/s de saldo/s generado/s correctamente!
					</h5>
					<hr />
					<div className='text-center'>
						<Button size='sm' className='mb-4 mt-3' variant='success' onClick={onClickNewAffectation}>
							<FontAwesomeIcon icon={faPlus} className='mr-2' />
							Nueva desafectacion o incrementos de saldos
						</Button>
					</div>
					<div className='mt-3 mb-3 d-flex justify-content-around'>
						<Button
							className='text-white-color btn-primary btn-lg'
							onClick={onClickGoToCreditExecution}
						>
							<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
							Volver a ejecución de presupuesto
						</Button>
					</div>
				</Card.Body>
			</Card>
		</Card>
	</Container>
)};

export default BalanceIncreaseStepFour;