export const STATES = {
    BORRADOR: 'BORRADOR',
	ACTIVO_PENDIENTE_DE_AUTORIZACION: 'ACTIVO_PENDIENTE_DE_AUTORIZACION',
	ANULADO: 'ACTIVO_PENDIENTE_DE_AUTORIZACION',
	AUTORIZADO_PENDIENTE_DE_PAGO: 'AUTORIZADO_PENDIENTE_DE_PAGO',
	PAGADO: 'PAGADO'
}
export const USER_ACTIONS = [
	{ id:"1", name: "Login" },
	{ id:"2", name:"Cambio Email" },
	{ id:"3", name:"Cambio Contraseña"},
	{ id:"4", name:"Envio Token" },
	{ id:"5", name:"Modificación Usuario" },
	{ id:"6", name:"Eliminar Usuario" },
	{ id:"7", name:"Nuevo Usuario" },
	{ id:"9", name:"Validación Usuario"}
];

export const TIPO_RECIBO = {
    ACTA_DE_RECEPCIÓN: 'Acta Recepción',
	CERTIFICADO_DE_SERVICIOS: 'Certificación de Servicio',
	CERTIFICADO_DE_OBRAS_PUBLICAS: 'Certificación de Obras Públicas'
}

export const INITIAL_URI = '/';
export const LOGIN_URI = '/login';
export const SET_PASSWORD_URI = '/set-password';
export const FORGOT_PASSWORD_URI = '/forgot-password';
export const RESET_PASSWORD_URI = '/reset-password';

export const CREDIT = '/credit';

{/* /ejecucion-presupuesto/partidas-presupuestarias */}
export const CREDIT_EXECUTION = '/ejecucion-presupuesto/partidas-presupuestarias';
export const CREDIT_EXECUTION_NEW_AFFECTATION_STEP_ONE = '/ejecucion-presupuesto/partidas-presupuestarias-nueva-etapa-uno';
export const CREDIT_EXECUTION_NEW_AFFECTATION_STEP_TWO = '/ejecucion-presupuesto/partidas-presupuestarias-nueva-etapa-dos';
export const CREDIT_EXECUTION_NEW_AFFECTATION_STEP_THREE = '/ejecucion-presupuesto/partidas-presupuestarias-nueva-etapa-tres';
export const CREDIT_EXECUTION_NEW_AFFECTATION_STEP_FOUR = '/ejecucion-presupuesto/partidas-presupuestarias-nueva-etapa-cuatro';
export const CREDIT_EXECUTION_AFFECTATION_HISTORY = '/ejecucion-presupuesto/partidas-presupuestarias-historial';
export const CREDIT_EXECUTION_CREDIT_QUERY = '/ejecucion-presupuesto/partidas-presupuestarias-consulta';
export const CREDIT_EXECUTION_BALANCE_DETAIL_COMPONENT = '/ejecucion-presupuesto/partidas-presupuestarias-detalle-de-saldo';
export const CREDIT_EXECUTION_BALANCE_TOTAL_MODAL_COMPONENT = '/ejecucion-presupuesto/partidas-presupuestarias-modal-total-saldo';
export const CREDIT_EXECUTION_DETAILS_REVERT = '/ejecucion-presupuesto/partidas-presupuestarias-detalles-revertir';
export const CREDIT_DETAIL = '/ejecucion-presupuesto/partidas-presupuestarias-detalles';

{/* /ejecucion-presupuesto/partidas-presupuestarias/modificacion-de-credito */}
export const CREDIT_STEP_ONE = '/ejecucion-presupuesto/partidas-presupuestarias-modificacion-de-credito-etapa-uno';
export const CREDIT_STEP_TWO = '/ejecucion-presupuesto/partidas-presupuestarias-modificacion-de-credito-etapa-dos';

{/* /ejecucion-presupuesto/partidas-presupuestarias/compensacion-de-partidas */}
export const COMPENSATION_STEP_ONE = '/ejecucion-presupuesto/partidas-presupuestarias-compensacion-de-partidas-etapa-uno';
export const COMPENSATION_STEP_TWO = '/ejecucion-presupuesto/partidas-presupuestarias-compensacion-de-partidas-etapa-dos';
export const COMPENSATION_STEP_THREE = '/ejecucion-presupuesto/partidas-presupuestarias-compensacion-de-partidas-etapa-tres';

{/* /ejecucion-presupuesto/partidas-presupuestarias/traspaso-de-saldos-de-partidas */}
export const BALANCE_TRANSFER_STEP_ONE = '/ejecucion-presupuesto/traspaso-de-saldos-de-partidas-etapa-uno';
export const BALANCE_TRANSFER_STEP_TWO = '/ejecucion-presupuesto/traspaso-de-saldos-de-partidas-etapa-dos';
export const BALANCE_TRANSFER_STEP_THREE = '/ejecucion-presupuesto/traspaso-de-saldos-de-partidas-etapa-tres';
export const BALANCE_TRANSFER_STEP_FOUR = '/ejecucion-presupuesto/traspaso-de-saldos-de-partidas-etapa-cuatro';

{/* /ejecucion-presupuesto/partidas-presupuestarias/desafectaciones-o-incrementos-de-partidas */} 
export const DISAFFECTATIONS_STEP_ONE = '/ejecucion-presupuesto/desafectaciones-o-incrementos-de-partidas-etapa-uno';
export const DISAFFECTATIONS_STEP_TWO = '/ejecucion-presupuesto/desafectaciones-o-incrementos-de-partidas-etapa-dos';
export const DISAFFECTATIONS_STEP_THREE = '/ejecucion-presupuesto/desafectaciones-o-incrementos-de-partidas-etapa-tres';
export const DISAFFECTATIONS_STEP_FOUR = '/ejecucion-presupuesto/desafectaciones-o-incrementos-de-partidas-etapa-cuatro';

{/* /ejecucion-presupuesto/partidas-presupuestarias/incorporacion-de-partidas */}
export const INCORPORATION_CREDIT_STEP_ONE = '/ejecucion-presupuesto/partidas-presupuestarias/incorporacion-de-partidas-etapa-uno';
export const INCORPORATION_CREDIT_STEP_TWO = '/ejecucion-presupuesto/partidas-presupuestarias/incorporacion-de-partidas-etapa-dos';

{/* /ejecucion-presupuesto/reversion-de-residuos-pasivos */}
export const REVERT_PASSIVE_REMAINING_BY_SERVICE = '/ejecucion-presupuesto/reversion-de-residuos-pasivos';

{/* /ejecucion-presupuesto/pre-carga */}
export const PRE_LOAD = '/ejecucion-presupuesto/pre-carga';

{/* /ejecucion-presupuesto/reporte-residuos-pasivos-por-servicio */}
export const REPORT_CONVERTION = '/ejecucion-presupuesto/reporte-residuos-pasivos-por-servicio';

{/* /seguridad/usuarios */}
export const USER_LIST = '/seguridad/usuarios';
export const USER_LIST_JURISDICTION = '/seguridad/usuarios-jurisdiccion';
export const USER_NEW = '/seguridad/usuarios-nuevo';
export const USER_EDIT = '/seguridad/usuarios-editar';
export const EDIT_PASSWORD = '/seguridad/usuarios-editar-contrasena';
export const USER_DETAIL = '/seguridad/usuarios-detalles';
export const PROFILE_LIST = '/seguridad/usuarios-perfiles';

{/* /reportes/ejecucion-presupuesto */}
export const REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION = '/reportes/ejecucion-presupuesto/analitico-de-contabilidad-de-presupuesto';
export const REPORT_BALANCE_STATUS = '/reportes/ejecucion-presupuesto/estado-de-saldos';
export const REPORT_INSTRUMENT_LEGAL_MODIFY = '/reportes/ejecucion-presupuesto/modificaciones-por-instrumento-legal';
export const REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT = '/reportes/ejecucion-presupuesto/comprobantes-por-expediente';
export const REPORT_DETAIL_PASSIVE_RESIDUES = '/reportes/ejecucion-presupuesto/detalle-de-residuos-pasivos';
export const REPORT_DETERMINATION_PASSIVE_RESIDUES = '/reportes/ejecucion-presupuesto/determinacion-de-residuos-pasivos';
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION = '/reportes/ejecucion-presupuesto/estado-de-ejecucion-de-presupuesto';
export const REPORT_CREDIT_MODIFY_CREDIT_EXECUTION = '/reportes/ejecucion-presupuesto/estado-de-modificacion-de-los-creditos';
export const REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION = '/reportes/ejecucion-presupuesto/estado-de-movimientos-de-creditos';
export const REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION = '/reportes/ejecucion-presupuesto/listado-de-pagos';
export const REPORT_TABLE_TOTALS_CREDIT_EXECUTION = '/reportes/ejecucion-presupuesto/totales';
export const REPORT_ACCRUAL_BASIS = "/reportes/ejecucion-presupuesto/base-devengado";
export const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED = '/reportes/ejecucion-presupuesto/generados';
export const REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION = '/reportes/ejecucion-presupuesto/registro-analitico-de-presupuesto-de-obras';
export const REPORT_DELAYED_FILES_CREDIT_EXECUTION = '/reportes/ejecucion-presupuesto/expedientes-demorados';
export const REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES = '/reportes/ejecucion-presupuesto/pendientes-de-resolucion-de-residuos-pasivos';

{/* /reportes/ejecucion-financiera */}
export const REPORT_DELIVERY_ORDERS_FUND_REQUEST = '/reportes/ejecucion-financiera/ordenes-de-entrega';
export const REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST = '/reportes/ejecucion-financiera/ordenes-de-pago-por-servicio';
export const REPORT_BANK_ACCOUNT_FUND_REQUEST = '/reportes/ejecucion-financiera/pedido-de-fondos-por-cuenta-bancaria';
export const REPORT_PROVIDER_FUND_REQUEST = '/reportes/ejecucion-financiera/pedido-de-fondos-por-proveedor';
export const REPORT_SERVICE_DIRECTION_FUND_REQUEST = '/reportes/ejecucion-financiera/pedido-de-fondos-por-servicio';
export const REPORT_FUND_REQUEST = '/reportes/ejecucion-financiera/pedido-de-fondos';
export const REPORT_NEW_FUND_REQUEST = '/reportes/ejecucion-financiera/nuevo-pedido-de-fondos';

{/* /ejecucion-financiera/configuracion-financiera/erogaciones-figurativas */}
export const FIGURATIVE_EXPEDITURES = '/ejecucion-financiera/configuracion-financiera/erogaciones-figurativas';
export const FIGURATIVE_EXPEDITURES_NEW = '/ejecucion-financiera/configuracion-financiera/erogaciones-figurativas-nuevo';
export const FIGURATIVE_EXPEDITURES_EDIT = '/ejecucion-financiera/configuracion-financiera/erogaciones-figurativas-editar';

{/* /ejecucion-presupuesto/configuracion-presupuestaria/plantilla-de-haberes */}
export const WAGES_TEMPLATE_LIST = '/ejecucion-presupuesto/configuracion-presupuestaria/plantilla-de-haberes';
export const WAGES_TEMPLATE_NEW = '/ejecucion-presupuesto/configuracion-presupuestaria/plantilla-de-haberes-nuevo';
export const WAGES_TEMPLATE_EDIT = '/ejecucion-presupuesto/configuracion-presupuestaria/plantilla-de-haberes-editar';
export const WAGES_TEMPLATE_DETAIL = '/ejecucion-presupuesto/configuracion-presupuestaria/plantilla-de-haberes-detalles';

{/* /ejecucion-presupuesto/configuracion-presupuestaria/conceptos-de-haberes */}
export const CONCEPT_LIST = '/ejecucion-presupuesto/configuracion-presupuestaria/conceptos-de-haberes';
export const CONCEPT_NEW  = '/ejecucion-presupuesto/configuracion-presupuestaria/conceptos-de-haberes-nuevo';
export const CONCEPT_EDIT  = '/ejecucion-presupuesto/configuracion-presupuestaria/conceptos-de-haberes-editar';

{/* /ejecucion-presupuesto/orden-de-pago */}
export const ORDER_PAY_LIST = '/ejecucion-presupuesto/orden-de-pago';
export const ORDER_PAY_NEW = '/ejecucion-presupuesto/orden-de-pago-nueva';
export const ORDER_PAY_EDIT = '/ejecucion-presupuesto/orden-de-pago-editar';
export const ORDER_PAY_DETAILS = '/ejecucion-presupuesto/orden-de-pago-detalles';

{/* /ejecucion-financiera/pedido-de-fondos */}
export const FUND_REQUESTS_LIST = '/ejecucion-financiera/pedido-de-fondos';
export const FUND_REQUESTS_NEW = '/ejecucion-financiera/pedido-de-fondos-nuevo';
export const FUND_REQUESTS_EDIT = '/ejecucion-financiera/pedido-de-fondos-editar';
export const FUND_REQUESTS_DETAILS = '/ejecucion-financiera/pedido-de-fondos-detalles';

{/* /ejecucion-financiera/orden-de-entrega */}
export const DELIVERY_ORDER_LIST = '/ejecucion-financiera/orden-de-entrega';
export const DELIVERY_ORDER_NEW = '/ejecucion-financiera/orden-de-entrega-nuevo';
export const DELIVERY_ORDER_EDIT = '/ejecucion-financiera/orden-de-entrega-editar';
export const DELIVERY_ORDER_DETAILS = '/ejecucion-financiera/orden-de-entrega-detalles';
export const DELIVERY_ORDER_NEW_MASSIVE = '/ejecucion-financiera/orden-de-entrega-nuevo-masivo';

{/* /departamento-suministro/orden-de-provision */}
export const PROVISION_ORDER_LIST  = '/departamento-suministro/orden-de-provision';
export const PROVISION_ORDER_NEW = '/departamento-suministro/orden-de-provision-nuevo';
export const PROVISION_ORDER_EDIT = '/departamento-suministro/orden-de-provision-editar';
export const PROVISION_ORDER_TO_CLONE = '/departamento-suministro/orden-de-provision-clonar';
export const PROVISION_ORDER_DETAILS = '/departamento-suministro/orden-de-provision-detalles';

{/* /ejecucion-financiera/cuentas-bancarias */}
export const BANK_ACCOUNT_LIST = '/ejecucion-financiera/cuentas-bancarias';
export const BANK_ACCOUNT_NEW = '/ejecucion-financiera/cuentas-bancarias-nuevo';
export const BANK_ACCOUNT_EDIT = '/ejecucion-financiera/cuentas-bancarias-editar';
export const BANK_ACCOUNT_DETAILS = '/ejecucion-financiera/cuentas-bancarias-detalles';

{/* /auditoria/usuarios */}
export const AUDITS_USERS = '/auditoria/usuarios';

{/* /auditoria/presupuesto */}
export const AUDITS_LIST = '/auditoria/presupuesto';

{/* /configuracion/alertas */}
export const ALERT_LIST = '/configuracion/alertas';
export const ALERT_NEW = '/configuracion/alertas-nuevo';
export const ALERT_EDIT = '/configuracion/alertas-editar';

{/* /configuracion/ejercicios */}
export const EXERCISES_LIST = '/exercises-list';
export const EXERCISE_NEW = '/exercise-new';
export const EXERCISE_EDIT_STEP_ONE = '/exercise-edit-1';
export const EXERCISE_EDIT_STEP_TWO = '/exercise-edit-2';
export const EXERCISE_DETAILS = '/exercise-details';
export const EXERCISE_SERVICE_ASOCIATION = '/exercise-service-asociation';

{/* /configuracion/expedientes */}
export const EXPEDIENT_LIST = '/configuracion/expedientes';
export const EXPEDIENT_EDIT = '/configuracion/expedientes-editar';

{/* /configuracion/servicios-administrativos */}
export const SERVICE_ADMINISTRATIVE_LIST = '/configuracion/servicios-administrativos';
export const SERVICE_ADMINISTRATIVE_NEW = '/configuracion/servicios-administrativos-nuevo';
export const SERVICE_ADMINISTRATIVE_EDIT = '/configuracion/servicios-administrativos-editar';
export const SERVICE_ADMINISTRATIVE_DETAILS = '/configuracion/servicios-administrativos-detalles';
export const SERVICE_ADMINISTRATIVE_USER = '/configuracion/servicios-administrativos-usuario';
export const SERVICE_ADMINISTRATIVE_ORGANISM = '/configuracion/servicios-administrativos-organismo';

{/* /configuracion/organismos-administrativos */}
export const ADMINISTRATIVE_ORGANISM_LIST = '/configuracion/organismos-administrativos';
export const ADMINISTRATIVE_ORGANISM_NEW = '/configuracion/organismos-administrativos-nuevo';
export const ADMINISTRATIVE_ORGANISM_EDIT = '/configuracion/organismos-administrativos-editar';
export const ADMINISTRATIVE_ORGANISM_DETAILS = '/configuracion/organismos-administrativos-detalles';

{/* /configuracion/leyendas */}
export const CAPTION_LIST = '/configuracion/leyendas';
export const CAPTION_NEW = '/configuracion/leyendas-nuevo';
export const CAPTION_EDIT = '/configuracion/leyendas-editar';

{/* /configuracion/sistema-mantenimiento */}
export const MAINTENANCE_SYSTEM_LIST = '/configuracion/sistema-mantenimiento';

{/* /notificaciones */}
export const NOTIFICATION_LIST  = '/notificaciones';
export const NOTIFICATION_NEW  = '/notificaciones-nuevo';
export const NOTIFICATION_DETAILS = '/notificaciones-detalles';

{/* /manuales */}
export const USER_MANUAL_SERVICE_OPERATOR = '/files/operator-service-manual';
export const USER_MANUAL_PROFILE_HTC = '/files/htc-manual';

export const INDEX_PAGE = '/index';

export const ROUTE_PATHS = {
	INITIAL_URI,
	LOGIN_URI,
	SET_PASSWORD_URI,
	FORGOT_PASSWORD_URI,
	RESET_PASSWORD_URI,

	CREDIT,
	CREDIT_DETAIL,
	CREDIT_STEP_ONE,
	CREDIT_STEP_TWO,

	COMPENSATION_STEP_ONE,
	COMPENSATION_STEP_TWO,
	COMPENSATION_STEP_THREE,

	BALANCE_TRANSFER_STEP_ONE,
	BALANCE_TRANSFER_STEP_TWO,
	BALANCE_TRANSFER_STEP_THREE,
	BALANCE_TRANSFER_STEP_FOUR,

	DISAFFECTATIONS_STEP_ONE,
	DISAFFECTATIONS_STEP_TWO,
	DISAFFECTATIONS_STEP_THREE,
	DISAFFECTATIONS_STEP_FOUR,
	
	INCORPORATION_CREDIT_STEP_ONE,
	INCORPORATION_CREDIT_STEP_TWO,

	CREDIT_EXECUTION,
	CREDIT_EXECUTION_NEW_AFFECTATION_STEP_ONE,
	CREDIT_EXECUTION_NEW_AFFECTATION_STEP_TWO,
	CREDIT_EXECUTION_NEW_AFFECTATION_STEP_THREE,
	CREDIT_EXECUTION_NEW_AFFECTATION_STEP_FOUR,
	CREDIT_EXECUTION_AFFECTATION_HISTORY,
	CREDIT_EXECUTION_CREDIT_QUERY,
	CREDIT_EXECUTION_BALANCE_DETAIL_COMPONENT,
	CREDIT_EXECUTION_BALANCE_TOTAL_MODAL_COMPONENT,
	CREDIT_EXECUTION_DETAILS_REVERT,

	USER_LIST,
	USER_LIST_JURISDICTION,
	USER_NEW,
	USER_EDIT,
	EDIT_PASSWORD,
	USER_DETAIL,

	PROFILE_LIST,

	REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT,
	REPORT_INSTRUMENT_LEGAL_MODIFY,
	REPORT_BUDGET_STATUS_CREDIT_EXECUTION,
	REPORT_CREDIT_MODIFY_CREDIT_EXECUTION,
	REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION,
	REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION,
	REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION,
	REPORT_DELAYED_FILES_CREDIT_EXECUTION,
	REPORT_TABLE_TOTALS_CREDIT_EXECUTION,
	REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED,
	REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION,
	REPORT_DELIVERY_ORDERS_FUND_REQUEST,
	REPORT_FUND_REQUEST,
	REPORT_BANK_ACCOUNT_FUND_REQUEST,
	REPORT_PROVIDER_FUND_REQUEST,
	REPORT_SERVICE_DIRECTION_FUND_REQUEST,
	REPORT_NEW_FUND_REQUEST,
	REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST,
	REPORT_BALANCE_STATUS,
	REPORT_ACCRUAL_BASIS,
	PRE_LOAD,

	FIGURATIVE_EXPEDITURES,
	FIGURATIVE_EXPEDITURES_NEW,
	FIGURATIVE_EXPEDITURES_EDIT,

	WAGES_TEMPLATE_LIST,
	WAGES_TEMPLATE_NEW,
	WAGES_TEMPLATE_EDIT,
	WAGES_TEMPLATE_DETAIL,

	REVERT_PASSIVE_REMAINING_BY_SERVICE,

	REPORT_CONVERTION,
	REPORT_DETERMINATION_PASSIVE_RESIDUES,
	REPORT_DETAIL_PASSIVE_RESIDUES,
	REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES,

	EXERCISES_LIST,
	EXERCISE_NEW,
	EXERCISE_EDIT_STEP_ONE,
	EXERCISE_EDIT_STEP_TWO,
	EXERCISE_DETAILS,
	EXERCISE_SERVICE_ASOCIATION,

	SERVICE_ADMINISTRATIVE_LIST,
	SERVICE_ADMINISTRATIVE_NEW,
	SERVICE_ADMINISTRATIVE_EDIT,
	SERVICE_ADMINISTRATIVE_DETAILS,
	SERVICE_ADMINISTRATIVE_USER,
	SERVICE_ADMINISTRATIVE_ORGANISM,

	ADMINISTRATIVE_ORGANISM_LIST,
	ADMINISTRATIVE_ORGANISM_NEW,
	ADMINISTRATIVE_ORGANISM_EDIT,
	ADMINISTRATIVE_ORGANISM_DETAILS,

	ORDER_PAY_LIST,
	ORDER_PAY_NEW,
	ORDER_PAY_EDIT,
	ORDER_PAY_DETAILS,

	FUND_REQUESTS_LIST,
	FUND_REQUESTS_NEW,
	FUND_REQUESTS_EDIT,
	FUND_REQUESTS_DETAILS,

	DELIVERY_ORDER_LIST,
	DELIVERY_ORDER_NEW,
	DELIVERY_ORDER_NEW_MASSIVE,
	DELIVERY_ORDER_EDIT,
	DELIVERY_ORDER_DETAILS,

	PROVISION_ORDER_LIST,
	PROVISION_ORDER_NEW,
	PROVISION_ORDER_EDIT,
	PROVISION_ORDER_TO_CLONE,
	PROVISION_ORDER_DETAILS,

	BANK_ACCOUNT_LIST,
	BANK_ACCOUNT_NEW,
	BANK_ACCOUNT_EDIT,
	BANK_ACCOUNT_DETAILS,

	AUDITS_LIST,
	AUDITS_USERS,

	USER_MANUAL_SERVICE_OPERATOR,
	USER_MANUAL_PROFILE_HTC,

	CAPTION_LIST,
	CAPTION_NEW,
	CAPTION_EDIT,

	ALERT_LIST,
	ALERT_NEW,
	ALERT_EDIT,

	NOTIFICATION_LIST,
	NOTIFICATION_NEW,
	NOTIFICATION_DETAILS,

	MAINTENANCE_SYSTEM_LIST,

	CONCEPT_LIST,
	CONCEPT_NEW,
	CONCEPT_EDIT,
	
	EXPEDIENT_LIST,
	EXPEDIENT_EDIT,

	INDEX_PAGE
};
